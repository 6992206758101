<template>
  <div
    class="wedding"
    :style="`background: url('${useRuntimeConfig().public.marcovasco.twicpicsPlanetveoUrl}${image}?twic=v1/output=webp/resize=${!breakpoints.isMobile ? 1920 : 390}x${!breakpoints.isMobile ? 625 : 525}')`"
  >
    <div class="infos-container">
      <div class="infos">
        <span class="title">{{ title }}</span>
        <NuxtImg v-if="icon" provider="twicpics" class="logo" :src="icon" />
        <span class="subtitle">{{ subtitle }}</span>
        <p class="description">
          {{ description }}
        </p>
        <NuxtLink v-if="cta" :to="cta.url">
          <button class="more">{{ cta.label }}</button>
        </NuxtLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps<{
  image: string;
  title: string;
  subtitle: string;
  icon?: string;
  description: string;
  cta?: {
    label: string;
    url: string;
  };
}>();
const breakpoints = useBreakpoints();
</script>

<style lang="scss" scoped>
@use '$/breakpoints.scss';
@use '$/metrics.scss';
@use '$/button.scss';

.wedding {
  justify-content: center;

  width: 100vw;
  height: 624px;

  background-position: center;
  background-size: cover;

  @include breakpoints.mobile() {
    justify-content: center;
    height: 524px;
  }

  .infos-container {
    justify-content: flex-end;
    width: metrics.$inner-width;

    .infos {
      flex-direction: column;
      align-items: center;
      align-self: center;
      justify-content: space-evenly;

      width: 520px;
      height: 500px;
      padding: 30px 64px;

      text-align: center;

      background: white;
      border-radius: 8px;

      @include breakpoints.mobile() {
        width: 100%;
        height: 404px;
        margin: 0 16px;
        padding: 24px;

        background: rgb(255 255 255 / 80%);
      }

      .title {
        font-size: 30px;
        font-weight: 800;

        @include breakpoints.mobile() {
          font-size: 20px;
        }
      }

      .logo {
        width: 64px;
        height: 64px;
      }

      .subtitle {
        font-size: 24px;
        font-weight: 700;

        @include breakpoints.mobile() {
          font-size: 18px;
        }
      }

      .description {
        font-size: 18px;
        font-weight: 400;

        @include breakpoints.mobile() {
          font-size: 16px;
        }
      }

      .more {
        @include button.plain();
      }
    }
  }
}
</style>
